<template>
    <div class="analysis__container">
        <!-- 비로그인시 로그인 유도 배너 notice-banner non-log-->
        <div class="notice-banner nonlog-banner"  v-if="Object.keys($parent.loginInfo).length == 0">
            <div class="nonlog-banner__txt--group">
                <p class="title"><span class="empha">비드톡톡 웹 또는 앱</span>에서<br>분석 요청을 하시면 </p>
                <p class="sub">분석 제시가 확인 및 투찰을 진행할 수 있어요.</p>
                <div class="nonlog-banner--bg anlaysis"></div>
            </div>
        </div>

        <!-- 로그인시-->
        <div class="notice-banner log-cont__container" v-if="Object.keys($parent.loginInfo).length != 0">
            <div class="log-cont__group log-cont-top__group">
                <div class="log-cont__unit log-cont-top__unit">
                    <h2 class="h2">오늘의 입찰 소식</h2>
                    <ul class="log-cont-top__box">
                        <li class="log-cont-top__item">투찰마감<span class="count">{{todayBddprFinCnt != null ? todayBddprFinCnt: '0'}}</span></li>
                        <li class="log-cont-top__item">참가마감<span class="count">{{todayRegstFinCnt != null ? todayRegstFinCnt: '0'}}</span></li>
                    </ul>
                </div>
            </div>
            <div class="log-cont__group tok-slide__wrap log-cont-slide__wrap">
                <div class="tok-slide__container log-cont-slide__container">
                    <div class="tok-slide--list log-cont-slide--list">
                        <ul class="tok-slide__item log-cont-slide__item" v-for="item in refactCaldrList" v-bind:key="item.id"
                            :class="(item.isHoliday ? 'holiday' : '') + (item.isToday ? ' today': '')">
                            <li class="log-cont-slide__item--date">
                                <p class="txt--title">{{item.printDt}}</p>
                            </li>
                            <li class="log-cont-slide__item--move count-fin">
                                <a class="log-cont-slide__item--move count-fin__box" @click="fnSelDate('bddprFin',item.targetDt)"><span class="txt--title">투찰마감</span>
                                    <p class="count">
                                        <span class="count--num">{{item.bddprFinCnt != null ? item.bddprFinCnt: '0'}}</span>
                                        <span class="count--icon"><i class="ic--arrow--view02"></i></span>
                                    </p>
                                </a>
                                <a class="log-cont-slide__item--move count-fin__box" @click="fnSelDate('regstFin',item.targetDt)"><span class="txt--title">참가마감</span>
                                    <p class="count">
                                        <span class="count--num"> {{item.regstFinCnt != null ? item.regstFinCnt : '0'}}</span>
                                        <span class="count--icon"><i class="ic--arrow--view02"></i></span>
                                    </p>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <ul class="tok-slide__arrow ">
                    <li class="tok-slide__arrow--item pre log-cont-slide__arrow--item" @click="clickPreviosSlide()">previos</li>
                    <li class="tok-slide__arrow--item next log-cont-slide__arrow--item" @click="clickNextSlide()">next</li>
                </ul>
            </div>
        </div>
        <!-- /로그인시-->
        <div class="search-banner">
            <div class="search-banner__box">
                <p class="inquiry__total txt__title analysis__total">총<span>{{mock.notiTable.notiCnt}}</span>건</p>
            </div>
            <div class="bt-srch--rev search-banner__box">
                <div class="bt-srch--btn excel--btn" @click="fnExcelDown()" v-if="Object.keys($parent.loginInfo).length != 0"><span class="tit">엑셀</span></div>
                <div class="search">
                    <input type="text" placeholder="공고명, 공고번호를 검색해 보세요." v-model="findText" @keyup.enter="searchNoti($event.target.value)">
                    <button type="reset" class="btn__cancel" @click="findText=''; searchNoti()">취소</button>
                </div>
            </div>
        </div>
        <div class="layout__half announce">
            <!-- 로그인을 안했을 경우 -->
            <div class="announce__board--state non-log" v-if="Object.keys($parent.loginInfo).length == 0">
                <div class="announce__board--img"></div>
                <div class="announce__board--txt">
                    <p class="title">로그인 후, 이용 가능한 기능입니다.</p>
                    <p class="sub">회원이 아닌 경우, 회원가입 후 로그인해 주세요.</p>
                </div>
                <div class="announce__board--button">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cb" @click="$goMenu('joinForm')">회원가입</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta" @click="$goMenu('login')">로그인</a>
                </div>
            </div>
            <!-- /로그인을 안했을 경우 -->
            <!-- 분석요청한 공고가 없을 경우 -->
            <div class="entire-search entire-search--no-res no-res" v-if="Object.keys($parent.loginInfo).length != 0 && mock.notiTable.noti.length == 0 && mock.notiTable.notiCnt == 0">
                <div class="no-res__group">
                    <div class="no-res--img"></div>
                    <p class="title">분석 요청한 공고가 없습니다.</p>
                    <p class="sub">맞춤 공고에서 공고 분석 요청을 해주세요.</p>
                </div>
                <a href="#" onclick="return false" class="btn-basic btn-line-cb" @click="$goMenu('notiSuggest')">나의 맞춤공고 보기</a>
            </div>
            <!-- 검색 결과가 없을 경우 -->
            <div class="announce__board--state non-result" v-if="Object.keys($parent.loginInfo).length != 0 && mock.notiTable.notiCnt == 0 && mock.notiTable.noti.length != 0">
                <div class="announce__board--img"></div>
                <div class="announce__board--txt">
                    <p class="title">검색어와 일치하는<span class="mobile"> 분석 요청 공고가 없습니다.</span></p>
                </div>
            </div>
            <!-- /검색 결과가 없을 경우 -->
            <!-- 분석실 목록-->
            <span v-for="items in mock.notiTable.notiGroup" v-bind:key="items.id">
                    <ul class="announce__date">
                        <li class="announce__date--mmdd"><h3 class="txt__title">{{ $moment(items[0].bddprFinDt).format('MM월 DD일 (ddd)') }}</h3></li>
                        <li class="announce__date--count"><p>{{items.length}}</p><span>건</span></li>
                    </ul>
                    <div>
                    <div class="layout__item announce--item" v-for="(item) in items" v-bind:key="item.id">
                        <!-- *투찰 현황 condition-badge__item 뒤에 클래스 덧붙임
                            투찰전 > before, 투찰가능 > ing, 투찰마감 > fin, 개찰완료 > announce,  참가신청 > application -->
                    <div class="announce__group condition-badge announce--item--badge">
                            <p class="condition-badge__item" :class="item.notiStatus == '투찰전' ? 'before' :
                                item.notiStatus == '투찰가능' ? 'ing' :
                                item.notiStatus == '투찰마감' ? 'fin' : item.notiStatus == '개찰완료' ? 'announce': ''"><span></span></p>
                           <button class="application-check" v-show="item.regstVisibleYn == 'Y'" :class="item.bddprRegstYn== 'Y' ? 'on': ''" @click="updateCustNotiRegstStatus(item)">참가신청완료</button>
                       </div>

                    <a class="announce__group announce--item--move-detail" @click="goMenu('notiDetail',item.notiId)">
                        <p class="txt__title">
                            <span class="condition-badge__item application" v-show="item.regstVisibleYn == 'Y' && item.notiStatus == '투찰가능'"><span></span></span>
                            <span class="bdg-code code07 mr4" v-show="item.rebidNo > 0">재입찰</span>
                            {{item.notiNm}}</p>
                        <div class="viw-all" v-show="deviceType == 'Desktop'"><p class="txt__title">{{item.notiNm}}</p></div>
                        <div class="announce__copy">
                                <div class="announce__copy--item">
                                    <span class="txt__sub">{{item.notiNo}}{{item.notiSeq != null ? ('-' + item.notiSeq) : ''}}</span>
                                    <button type="button" class="btn__copy" @click="copyNotiNo(item.notiNo)"></button>
                                </div>
                            </div>
                            <ul class="announce__info">
                                <li class="announce__info--list announce__info--company"><span class="txt__title">지역</span><span class="txt__sub">{{item.areaNmConcat}}</span></li>
                                <li class="announce__info--list announce__info--company">
                                <span class="txt__title">면허</span>
                                <div class="main-lisc bdg-lic">
                                    <i class="main-lisc--ic bdg-lic--ic main" v-show="item.noticeMainLicnsList.length > 0">주력</i>
                                    <div class="viw-all" v-show="deviceType == 'Desktop'">
                                        <p class="txt__title">{{item.noticeMainLicnsList.map((e) => e.licnsNm).join(" | ")}}</p>
                                    </div>
                                    <span class="txt__sub">
                                     {{item.licnsNmConcat}}
                                    </span>
                                </div>
                            </li>
<!--                                <li class="announce__info&#45;&#45;list announce__info&#45;&#45;company"><span class="txt__title"  v-show="item.noticeMainLicnsList != null ">주력</span>-->
<!--                                <span class="txt__sub" v-for="licns in item.noticeMainLicnsList" v-bind:key="licns.id">{{licns.licnsNm}}</span>-->
<!--                            </li>-->

<!--                                <li class="announce__info&#45;&#45;list announce__info&#45;&#45;company"><span class="txt__title">면허</span><span class="txt__sub">{{item.licnsNmConcat}}</span></li>-->
                                <li class="announce__info--list"><span class="txt__title">기초금액</span><span class="txt__sub">{{item.baseAmt | currency}}</span></li>
                            </ul>
                    </a>
                    <div class="announce__group announce__period">
                        <p class="txt__title" v-show="item.regstVisibleYn == 'Y' ">참가마감</p>
                        <span class="txt__sub" :class="item.isRegDt ? 'announce__period--today' : ''"
                              v-show="item.regstVisibleYn == 'Y'">{{ $moment(item.regstFinDt).format('MM/DD(ddd)  HH:mm') }} </span>
                        <span class="dividing-line" v-show="item.regstVisibleYn == 'Y' && item.notiStatus == '투찰가능'"></span>
                        <p class="txt__title">투찰마감</p>
                        <span class="txt__sub" :class="item.isBddprDt ? 'announce__period--today' : ''">{{ $moment(item.bddprFinDt).format('MM/DD(ddd)  HH:mm') }}</span>
                    </div>
                    <div class="announce__group announce__analysis--info">
                        <img :src="item.iconImg" alt="분석가 이미지" class="announce__analysis--analysist">
                        <span class="txt__title">{{item.anlstNickNm}}</span>
                    </div>
                    <div class="announce__group active" v-show="item.anlstChoiceFlag != 'Y' && item.anlsBddprAmt == 0">
                       <button type="button" class="announce--cta-btn btn-basic btn__non-active"><span>분석 중</span></button>
                    </div>
                    <div class="announce__group announce--price" v-show="item.anlsBddprAmt != 0" :class="item.anlstChoiceFlag=='Y' ? 'active' : ''">
                        <button type="button" class="announce--cta-btn btn-basic announce--price--view" @click="confirmAmt(item)"><span>분석제시가 확인</span></button>
                        <p class="txt--title price" @click="copyAnlsBddprAmt(item.anlstChoiceFlag=='Y' ? item.anlsBddprAmt.toString() : '0')"><span>{{item.anlstChoiceFlag=='Y' ? item.anlsBddprAmt : '0' | currency}}</span></p>
                        <button class="btn-basic btn-cta announce--price--btn bid-go" :class="item.bddprConfYn == 'Y' ? 'active' : ''"
                                @click="goSite(item)"><span>투찰</span></button>
                        <button class="btn-basic btn-line-cb announce--price--btn bid-fin" :class="item.bddprConfYn == 'Y' ? 'active' : ''"
                                @click="updateCustNotiBddprStatus(item)"><span>투찰완료</span></button>
                    </div>
               </div>
                        </div>
                </span>
            <!-- /분석실 목록-->
        </div>
        <div class="btn__moveTop">
            <button class="btn-basic btn-line-cb analist--btn--more" v-show="mock.notiTable.noti.length  < mock.notiTable.notiCnt && mock.notiTable.notiCnt > anlsPageUnit"
                    @click="anlsPage++; getNotiAnlsProgInfoList()"><span>{{mock.notiTable.notiCnt - mock.notiTable.noti.length > anlsPageUnit ? anlsPageUnit : mock.notiTable.notiCnt  - mock.notiTable.noti.length}}개 더 보기&#8595;</span>
            </button>
        </div>
        <div class="btn__moveTop" style="display: none;">
            <a href="#" onclick="return false" @click="moveTop()"></a>
        </div>

        <!-- 개인정보 수집 동의 팝업 -->
        <div class="popup__wrap download__wrap" id="popupWrap">
            <div class="popup__item--container">
                <div class="popup__item--group download__area">
                    <div class="popup__item--head download__head">
                        <h3 class="txt__title">비드톡톡 앱 다운로드</h3>
                    </div>
                    <div class="popup__item--body download__body">
                        <div class="download__body--group phone">
                            <p class="txt__title">Welcome 환영합니다!</p>
                            <p class="txt__sub">휴대폰 번호를 입력해주시면<br>앱 다운로드 링크를 문자로 보내드려요.</p>
                            <div class="input">
                                <input type="text" v-model="phoneNum" placeholder="휴대폰 번호를 입력해 주세요." maxlength="11">
                            </div>
                        </div>
                        <div class="download__body--group agree">
                            <div class="download__body--chekbox">
                                <div class="check">
                                    <input type="checkbox" id="check15" v-model="indvdlinfoAuthz">
                                    <label for="check15"></label>
                                </div>
                                <a href="#" onclick="return false" @click="indvInAuYn()">(필수) 개인정보 수집 및 이용 동의</a>
                            </div>
                            <p class="txt__sub">앱 다운로드 링크 전송을 위해 전화번호를<br>수집하며, 수집일로부터 3개월간 보관됩니다.</p>
                        </div>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cb" @click="closePop()">닫기</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta" @click="sendSms()">문자 발송</a>
                </div>
            </div>
        </div>
        <!-- /개인정보 수집 동의 팝업 -->
        <!-- 분석제시가 열람 제한 팝업 -->
        <div class="popup__wrap analy-limit active" v-show="isPopAnlsRestrain">
            <div class="popup__item--container">
                <div class="popup__item--group">
                    <div class="popup__item--head">
                        <div class="txt__title">분석제시가 확인 제한</div>
                    </div>
                    <div class="popup__item--body">
                        <p class="txt__sub">분석제시가 확인은 <span>투찰 시작 이후 가능합니다.</span></p>
                        <p class="txt time">투찰 시작: <span>{{bddprDt}}</span></p>
                    </div>
                </div>
                <div class="popup__button--wrap single popup__close">
                    <a href="#" onclick="return false" class="btn-basic btn-cta" @click="closeAnlsPop()">확인</a>
                </div>
            </div>
        </div>
        <!-- 분석제시가 열람 제한 팝업  -->

        <!-- 참가신청 미완료 팝업 -->
        <div class="popup__wrap confim application-cek active" v-show="isPopRegstConfirm">
            <div class="popup__item--container">
                <div class="popup__item--group application-cek--group">
                    <div class="popup__item--head">
                        <p class="txt__title">참가신청 미완료</p>
                    </div>
                    <div class="popup__item--body">
                        <p class="txt__sub">를 체크하신 후,
                            분석제시가를 확인할 수 있어요.</p>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="isPopRegstConfirm = false;">닫기</a>
                </div>
            </div>
        </div>
        <!-- /참가신청 미완료 팝업 -->
        <!-- 분석제시가 확인 팝업 -->
        <div class="popup__wrap confim price-cek active" v-show="isPopBddprConfirm">
            <div class="popup__item--container">
                <div class="popup__item--group price-cek-limit--group">
                    <div class="popup__item--head">
                        <p class="txt__title">분석제시가 확인</p>
                    </div>
                    <div class="popup__item--body">
                        <p class="txt__sub">분석제시가를 확인하시겠습니까?</p>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="#" onclick="return false" class="btn-basic btn-line-cb popup__close" @click="isPopBddprConfirm = false">아니오</a>
                    <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="anlsAmtChoice()">네</a>
                </div>
            </div>
        </div>
        <!-- /분석제시가 확인 팝업 -->
    </div>

</template>
<script>
    import mock from './mock';
    import $ from 'jquery';

    export default {
        data() {
            return {
                tabNo: 0,
                deviceType: 'Desktop',

                //분석실 쿼리
                mock,
                loginInfo: {},
                snackbar: false,
                copyText: '',
                findText: '',
                // findNotiCnt: 0,
                kword: 'notiNmNo',
                searchCulum: '',
                nothQuqlfYn: '',
                isDelYn: 'N',
                custNotiStrtDate: '',
                custNotiFinDate: '',
                regstCnt: 0,
                bddprStrtCnt: 0,
                bddprFinCnt: 0,
                indvdlinfoAuthz: false,
                phoneNum: '',
                isPopAnlsRestrain: false,   //분석제시가확인 제한
                isPopRegstConfirm: false,   //참가신청 미완료
                isPopBddprConfirm: false,   //분석제시가 확인 컨펌

                selNoti: {},    //선택 공고
                bddprDt: '',
                anlsPageUnit: 30,
                anlsPage: 0,
                todayRegstFinCnt: 0,
                todayBddprFinCnt: 0,

                /* 입찰달력 변수 */
                notiAnlsCaldrList: [], //입찰달력 데이터
                holidayMngmtList: [], //공휴일 데이터
                refactCaldrList: [], //재가공달력
                anlsStrtDt: this.$moment().format('YYYY-MM-DD'),    //시작일
                anlsEndDt: this.$moment().add(10, 'days').format('YYYY-MM-DD'),    //종료일
            }
        },
        watch: {
            isPopAnlsRestrain(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
            isPopRegstConfirm(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
            isPopBddprConfirm(newValue) {
                if (newValue == true) {
                    this.$scrollNon();
                } else {
                    this.$scrollActive();
                }
            },
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },

        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        async mounted() {
            this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));
            this.deviceType  = this.$getDeviceType();

            const btnCancel = document.querySelector('.btn__cancel');
            const searchInput = btnCancel.previousElementSibling; //input

            searchInput.addEventListener('focus', () => { // 검색어창 포커스 on
                searchInput.parentElement.classList.add('active');
            });

            searchInput.addEventListener('blur', () => { // 검색어창 포커스 off
                searchInput.parentElement.classList.remove('active');
            });
            searchInput.addEventListener('keyup', () => { // 검색어 입력시 취소버튼 나타나기
                const word = searchInput.value.length;
                if (word > 0) {
                    btnCancel.style.opacity = 1;
                } else {
                    btnCancel.style.opacity = 0;
                }
            });

            btnCancel.addEventListener('click', function () { //텍스트 초기화
                btnCancel.previousElementSibling.value = '';
                btnCancel.style.opacity = 0;
            });

            if (this.loginInfo != null) {

                //공휴일 정보
                await this.getHolidayMngmtList();

                //분석실 목록
                this.initCustNotiList();

                //분석실 통계
                this.getAnlsRoomInfo();

                //분석실 달력 목록
                this.getNotiAnlsCaldrList();
            } else {
                mock.notiTable.notiCnt = 0;
                mock.notiTable.notiGroup = {};
            }
        },
        methods: {
            /** 엑셀 다운로드 */
            fnExcelDown() {
                var fileName = JSON.parse(localStorage.getItem("custInfo")).cmpNm.toUpperCase() + '_' + this.$moment().format('YYYYMMDDHHmmss') + '.xls';
                var excel = [];
                // var data = [
                //     { notiNo: '', notiNm: '' },
                // ];

                for (const notiList of this.mock.notiTable.noti) {
                    const excelHeaders = { 공고번호:'', 공고명:'', 발주처:'', 지역:'', 면허:'', 등록마감:'', 투찰마감:'', 개찰일시:'', 기초금액:'', 제시금액:'', 제시지점:''};
                    excelHeaders.공고번호 = notiList.notiNo;
                    excelHeaders.공고명 = notiList.notiNm;
                    excelHeaders.발주처 = notiList.tlwtOrgnzNm;
                    excelHeaders.지역 = notiList.areaNmConcat;
                    excelHeaders.면허 = notiList.licnsNmConcat;
                    excelHeaders.등록마감 = notiList.regstFinDt;
                    excelHeaders.투찰마감 = notiList.bddprFinDt;
                    excelHeaders.개찰일시 = notiList.opbdDt;
                    excelHeaders.기초금액 = Number(notiList.baseAmt).toLocaleString();
                    if (notiList.anlstChoiceFlag == 'Y') {
                        excelHeaders.제시금액 = Number(notiList.anlsBddprAmt).toLocaleString();
                        excelHeaders.제시지점 = notiList.anlsBddprRt;
                    }
                    excel.push(excelHeaders);
                }

                if (this.mock.notiTable.noti.length > 0)
                    this.$excelDown(excel, 'sheet1', fileName);
            },
            /** 분석실 날짜 선택*/
            fnSelDate(type, dt) {
                this.custNotiFinDate = dt;
                this.custNotiStrtDate = dt;

                if (type == 'bddprFin') {
                    this.searchCulum = 'BDDPR_FIN_DT';
                } else if (type == 'regstFin') {
                    this.searchCulum = 'REGST_FIN_DT';
                }
                this.initCustNotiList();

            },

            /** 뒤로 슬라이드*/
            clickPreviosSlide() {
                this.anlsStrtDt = this.$moment(this.anlsStrtDt).subtract(10, 'days').format('YYYY-MM-DD');
                this.anlsEndDt = this.$moment(this.anlsEndDt).subtract(10, 'days').format('YYYY-MM-DD');

                this.refactCaldrList = [];
                this.getNotiAnlsCaldrList();
            },
            /** 앞으로 슬라이드*/
            clickNextSlide() {
                this.anlsStrtDt = this.$moment(this.anlsStrtDt).add(10, 'days').format('YYYY-MM-DD');
                this.anlsEndDt = this.$moment(this.anlsEndDt).add(10, 'days').format('YYYY-MM-DD');

                this.refactCaldrList = [];
                this.getNotiAnlsCaldrList();
            },
            dateToString(value) {
                // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
                if (value == '') return '';

                // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
                var js_date = new Date(value);

                // 연도, 월, 일 추출
                var year = js_date.getFullYear();
                var month = js_date.getMonth() + 1;
                var day = js_date.getDate();

                // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
                if (month < 10) {
                    month = '0' + month;
                }

                if (day < 10) {
                    day = '0' + day;
                }
                if (year == '1970' && month == '01' && day == '01') {
                    return '';
                }

                // 최종 포맷 (ex - '20211008')
                return year + '' + month + '' + day;
            },

            // 달력 가공
            refactCaldr() {
                var targetDt = this.$moment(this.anlsStrtDt);
                var anlsEndDt = this.$moment(this.anlsEndDt);
                var flag = true;

                while (flag) {

                    if (targetDt.format('YYYY-MM-DD') == anlsEndDt.format('YYYY-MM-DD')) {
                        flag = false;
                    } else {
                        var isHoliday = this.isHolidayCheck(targetDt);

                        var regstFinCnt = this.notiAnlsCaldrList.regstFinMap[targetDt.format('YYYYMMDD')];
                        var bddprFinCnt = this.notiAnlsCaldrList.bddprFinLMap[targetDt.format('YYYYMMDD')];

                        var isToday = this.$moment().format('YYYY-MM-DD') == targetDt.format('YYYY-MM-DD');
                        if (isToday == true) {
                            this.todayRegstFinCnt = regstFinCnt;
                            this.todayBddprFinCnt = bddprFinCnt;
                        }

                        var data = {
                            "printDt": targetDt.format('M/D'),
                            "targetDt": targetDt.format('YYYYMMDD'),
                            "isHoliday": isHoliday,
                            "isToday": isToday,
                            "regstFinCnt": regstFinCnt,
                            "bddprFinCnt": bddprFinCnt,
                        };

                        this.refactCaldrList.push(data);
                        targetDt = targetDt.add(1, 'days');
                    }
                }

            },
            //휴일 여부 체크
            isHolidayCheck(targetDt) {
                var index = this.holidayMngmtList.findIndex(item => item.holiday === targetDt.format('YYYY-MM-DD'));

                var dayOfWeek = targetDt.weekday();

                if (index != -1 || dayOfWeek == 0 || dayOfWeek == 6) {
                    return true;
                } else {
                    return false;
                }

            },

            //공휴일 정보
            async getHolidayMngmtList() {

                var param = {
                    "startDt": this.$moment().format('YYYY-MM-DD'),
                    "endDt": this.$moment().add(90, 'days').format('YYYY-MM-DD'),
                }
                await this.$post('/api/holidayMngmt/list', param)
                    .then(res => {
                        this.holidayMngmtList = res.holidayMngmtList;
                    })
                    .catch(error => {
                            console.log(error);
                        }
                    );

            },
            //분석현황 달력
            getNotiAnlsCaldrList() {

                this.notiAnlsCaldrList = [];

                var param = {
                    "anlsStrtDt": this.anlsStrtDt,
                    "anlsEndDt": this.anlsEndDt,
                    "custBizId": this.loginInfo.custBizList[0].id,
                    "licnsCd": null,
                    "omtpEcptYn": "N",
                    "omtpPclrYn": "N"
                };

                this.$post('/api/noti/notiAnlsCaldrList', param)
                    .then(res => {
                        this.notiAnlsCaldrList = res.data;
                        this.refactCaldr();
                    })
                    .catch(error => console.log(error));
            },

            //문자전송팝업 닫기
            closePop() {
                var popupWrap = document.getElementById("popupWrap");
                popupWrap.style.display = "none";
                this.phoneNum = "";
                this.indvdlinfoAuthz = false;
            },

            //분석제시가 확인제한 팝업 닫기
            closeAnlsPop() {
                this.isPopAnlsRestrain = false;
            },
            /** 개인정보 동의*/
            indvInAuYn() {
                if (this.indvdlinfoAuthz == false) {
                    this.indvdlinfoAuthz = true;
                } else if (this.indvdlinfoAuthz == true) {
                    this.indvdlinfoAuthz = false;
                }
            },

            //문자전송
            async sendSms() {
                if (this.phoneNum == "") {
                    this.$toast.success("휴대폰 번호를 입력해 주세요.", {
                        position: 'top-center',
                        timeout: 1000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        transitionDuration: 750,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true
                    });
                    return;
                }
                if (this.indvdlinfoAuthz == false) {
                    this.$toast.success("개인정보 수집에 동의해 주세요.", {
                        position: 'top-center',
                        timeout: 1000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        transitionDuration: 750,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true
                    });
                    return;
                }

                var popupWrap = document.getElementById("popupWrap");

                if (this.indvdlinfoAuthz == true) {
                    this.indvdlinfoAuthzYn = 'Y';
                }

                var clintIP = await this.$getPublicIP();

                var params = {
                    "recptnTelno": this.phoneNum.toString(),
                    "clientIp": clintIP,
                    "indvdlinfoAuthzYn": this.indvdlinfoAuthzYn
                };

                this.$http.post('/api/launcher/extrnl/sendAppLink', params)
                // eslint-disable-next-line no-unused-vars
                    .then(res => {
                        this.$toast.success("문자 발송이 완료되었습니다.", {
                            position: 'top-center',
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnHover: true,
                            transitionDuration: 750,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: "button",
                            icon: true
                        });
                        popupWrap.style.display = "none";
                        this.phoneNum = "";
                        this.indvdlinfoAuthz = false;
                    })

                    .catch(error => {
                            console.log(error);
                        }
                    );

            },

            //공고 상세페이지이동
            goMenu(menuUrl, id) {
                if (this.$route.path != "/" + menuUrl) // 현재 route와 다른 경우만 페이지 이동
                    window.open('/' + menuUrl + '/' + id, '_blank', 'width=1200,height=1000');
            },

            //문자전송팝업 오픈
            async openPop() {
                var popupWrap = document.getElementById("popupWrap");
                popupWrap.style.display = "block";

                var tracking = {
                    "trackingCd": "1",
                    "trackingAction" : "CLICK",
                    "trackingNm": "앱링크 다운로드",
                    "trackingDesc" : "",
                };

                await this.$insertTracking(tracking);
            },
            getAnlsRoomInfo() {
                if (this.loginInfo != null) {

                    this.$get('/api/home/anlsRoomInfo')
                        .then(res => {
                            this.regstCnt = res.regstCnt;
                            this.bddprStrtCnt = res.bddprStrtCnt;
                            this.bddprFinCnt = res.bddprFinCnt;

                        })
                        .catch(error => console.log(error));
                }
            },
            handleScroll() {
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const moveTopButton = document.querySelector('.btn__moveTop');

                if (scrollTop > 0) {
                    moveTopButton.style.display = 'block';
                } else {
                    moveTopButton.style.display = 'none';
                }
            },
            /** 가장 위로 이동*/
            moveTop() {
                //페이지 이동
                window.scroll({top: 0, left: 0, behavior: 'smooth'});
            },
            goSite(noti) {
                var url = '';
                if( noti.rpsntOrgnzCd == 1 ) { //나라장터
                    url = 'https://www.g2b.go.kr';
                    var AddUrl = "?bidno="+ noti.notiNo +"&searchType=2&bidSearchType=1";
                    url += encodeURI("/pt/menu/selectSubFrame.do?framesrc=/pt/menu/frameTgong.do?url=https://www.g2b.go.kr:8101/ep/tbid/tbidList.do"+AddUrl);
                }

                else if( noti.rpsntOrgnzCd == 3 ) { //국방부
                    this.copyNotiNo(noti.notiNo);
                    if(noti.notiKindCd=='0') {
                        if(noti.vltrnNotiYn=='Y') { // 수의여부
                            url = 'http://www.d2b.go.kr/Internet/jsp/peb/HI_PEB_Main.jsp?md=441&cfn=HI_PEB_OpenNego_Lst&txtCsrtName='+ noti.notiNm;
                        } else {
                            url = 'http://www.d2b.go.kr/Internet/jsp/peb/HI_PEB_Main.jsp?md=421&cfn=HI_PEB_Announce_Lst&txtG2bNumb='+ noti.notiNo;
                        }
                    } else if(noti.notiKindCd=='1') {
                        if(noti.vltrnNotiYn=='Y') { // 수의여부
                            url = 'http://www.d2b.go.kr/Internet/jsp/pdb/HI_PDB_Main.jsp?md=241&cfn=HI_PDB_OpenNego_Lst&txtRpstItnm='+ noti.notiNm;
                        } else {
                            url = 'http://www.d2b.go.kr/Internet/jsp/peb/HI_PEB_Main.jsp?md=421&cfn=HI_PEB_Announce_Lst&txtG2bNumb='+ noti.notiNo;
                        }
                    } else if(noti.notiKindCd=='2') {
                        if(noti.vltrnNotiYn=='Y') { // 수의여부
                            url = 'http://www.d2b.go.kr/Internet/jsp/pdb/HI_PDB_Main.jsp?md=241&cfn=HI_PDB_OpenNego_Lst&txtCsrtName='+ noti.notiNm;
                        } else {
                            url = 'http://www.d2b.go.kr/Internet/jsp/pdb/HI_PDB_Main.jsp?md=221&cfn=HI_PDB_Announce_Lst&txtG2bNumb='+ noti.notiNo;
                        }
                    }
                }

                else if( noti.rpsntOrgnzCd == 6 ) { //도로
                    var token = noti.notiNo.split( '-' );

                    if(noti.notiKindCd=='0' || noti.notiKindCd=='1') {
                        url = 'http://ebid.ex.co.kr/ebid/jsps/ebid/const/bidNoti/bidNotiCompanyList.jsp?';
                        url += 'in_out=O';
                        url += '&bid_type='+ (noti.notiKindCd=='0' ? 'C' : 'S');
                        url += '&list_gubun=N&status=E&startnum=1&endnum=10';
                        url += '&noti_no1='+ token[0];
                        url += '&noti_no2='+ token[1];
                        url += '&noti_title=&bid_cnt=0&s_noti_date=&e_noti_date=&method=';
                    } else {
                        url = 'http://ebid.ex.co.kr/ebid/jsps/ebid/buy/bidNoti/bidNotiCompanyList.jsp?';
                        url += 'notiNo1='+ token[0];
                        url += '&noti_no2='+ token[1];
                        url += '&notiTitle=&multiCnt=0&startDate=&endDate=&method=';
                    }
                }

                else if( noti.rpsntOrgnzCd == 8 ) { //마사회
                    // url = 'http://ebid.kra.co.kr/bid/bd_list_notice.jsp?b_code=&work_type=&is_sell=&is_from_main=true&b_to_me=&is_sell_result=&page=&order_place=&bid_name='+ noti.notiNm;
                    url = 'http://ebid.kra.co.kr/bid/notice/all/view.do?b_code='+ noti.notiNo.replace('KRA','');
                }

                // else if( noti.rpsntOrgnzCd == 13 ) { //수력원자력
                // 	url = 'http://ebiz.khnp.co.kr/MB_BID/B/mbqb400.jsp?gongoNum=' + noti.notiNo;
                // }

                // 정의된 사이트가 없을경우 메인 페이지로
                if(url == '') {
                    this.copyNotiNo(noti.notiNo);
                    url = this.rpsntOrgnzLink(noti.rpsntOrgnzCd);
                }

                window.open(url, "popup", "width=1024, height=600, left=30, top=30, scrollbars=no,titlebar=no,status=no,resizable=no,fullscreen=no");
            },
            rpsntOrgnzLink( rpsntOrgnzCd ) {

                var Link = null;

                if( rpsntOrgnzCd == 1 ){ //나라장터
                    Link = 'https://www.g2b.go.kr';
                }
                if( rpsntOrgnzCd == 2 ){ //한전
                    Link = 'http://srm.kepco.net';
                }
                if( rpsntOrgnzCd == 3 ){ //국방부
                    Link = 'http://www.d2b.go.kr';
                }
                if( rpsntOrgnzCd == 5 ){ //토주
                    Link = 'http://ebid.lh.or.kr';
                }
                if( rpsntOrgnzCd == 6 ){ //도로
                    Link = 'https://ebid.ex.co.kr';
                }
                if( rpsntOrgnzCd == 13 ){ //수력원자력
                    Link = 'http://ebiz.khnp.co.kr';
                }
                if( rpsntOrgnzCd == 10 ){ //수자원
                    Link = 'http://ebid.kwater.or.kr';
                }
                if( rpsntOrgnzCd == 11 ){ //인천공항
                    Link = 'http://ebid.airport.kr/UserMain.dc';
                }
                if( rpsntOrgnzCd == 9 ){ //가스
                    Link = 'http://bid.kogas.or.kr';
                }
                if( rpsntOrgnzCd == 7 ){ //한국통신
                    Link = 'http://silkroad.kt.co.kr';
                }
                if( rpsntOrgnzCd == 8 ){ //마사회
                    Link = 'http://ebid.kra.co.kr';
                }
                if( rpsntOrgnzCd == 4 ){ //전자통신연구원
                    Link = 'http://ebid.etri.re.kr/ebid/';
                }
                if( rpsntOrgnzCd == 20 ){ //철도공사
                    Link = 'http://ebid.korail.com';
                }
                if( rpsntOrgnzCd == 19 ){ //철도시설공단
                    Link = 'http://ebid.kr.or.kr';
                }
                if( rpsntOrgnzCd == 24 ){ //석유공사
                    Link = 'http://ebid.knoc.co.kr';
                }
                if( rpsntOrgnzCd == 25 ){ //학교장터
                    Link = 'http://www.s2b.kr';
                }

                return Link;
            },

            /** 분석공고 검색*/
            searchNoti(value) {
                if (value != null) {
                    this.findText = value;
                }
                // this.findNotiCnt = 0;
                mock.notiTable.noti = [];

                this.getNotiAnlsProgInfoList();

            },
            /** 분석공고 초기화*/
            initCustNotiList() {
                this.anlsPage = 0;
                mock.notiTable.noti = [];
                mock.notiTable.notiGroup = {};

                this.getNotiAnlsProgInfoList();
            },
            /** 분석공고 그룹화*/
            refactCustNotiList() {
                mock.notiTable.notiGroup = {};
                mock.notiTable.noti.forEach(item => {
                    // if (item.notiNm.toString().indexOf(this.findText) != -1 || item.notiNo.toString().indexOf(this.findText) != -1) {
                    const category = this.$moment(item.bddprFinDt).format('YYYY-MM-DD');

                    if (!mock.notiTable.notiGroup[category]) {
                        mock.notiTable.notiGroup[category] = []; // Initialize an array for the category
                    }
                    mock.notiTable.notiGroup[category].push(item);
                    // this.findNotiCnt++;
                    // }
                });
            },
            getNotiAnlsProgInfoList() {
                var custInfo = JSON.parse(localStorage.getItem("custInfo"));

                if (custInfo == null) {
                    return;
                }

                var param = {
                    pageUnit: this.anlsPageUnit,
                    pageIndex: this.anlsPage,
                    strtRecordIntex: this.anlsPageUnit * this.anlsPage,
                    searchDate: this.$moment().format('YYYYMMDD HH:mm'),
                    custBizId: custInfo.custBizList[0].id,
                    kword: this.kword,
                    findText: this.findText,
                    searchCulum: this.searchCulum,
                    nothQuqlfYn: this.nothQuqlfYn,
                    strtDate: this.custNotiStrtDate,
                    finDate: this.custNotiFinDate,
                    delYn: this.delYn,

                    // orderBySt: 'bddprFinDt',
                    // notiOpbdListYn: 'Y',
                };

                this.$parent.$parent.isLoadingBar(true);
                this.$post('/api/noti/notiAnlsProgInfoList', param)
                    .then(res => {
                        var notiList = res.notiProgInfoList;
                        this.mock.notiTable.notiCnt = res.notiProgInfoCnt;

                        const today = this.$moment();

                        for (var i in notiList) {

                            // 분석제시목록
                            var custNotiAnlsList = notiList[i]['custNotiAnlsList'];
                            var anlsBddprAmt = 0;
                            var anlsBddprRt = 0;
                            var custNotiId = '';
                            var anlstChoiceFlag = '';
                            var custNotiAnlsId = '';
                            // var iconImg = '';
                            //var anlstNickNm = '';
                            for (var j in custNotiAnlsList) {
                                if (j == 0) {
                                    anlsBddprAmt = custNotiAnlsList[j].anlsBddprAmt; // 분석제시가
                                    anlsBddprRt = custNotiAnlsList[j].anlsBddprRt; // 분석지점
                                    custNotiId = custNotiAnlsList[j].custNotiId; // 분석지점
                                    anlstChoiceFlag = custNotiAnlsList[j].anlstChoiceFlag;
                                    custNotiAnlsId = custNotiAnlsList[j].id;
                                    //anlstNickNm = custNotiAnlsList[j].anlstNickNm; // AI닉네임
                                    // iconImg = '/'+custNotiAnlsList[j].iconImg; // AI닉네임
                                }
                            }

                            // <img :src="mock.notiTable.notiAnlstList.find((e) => e.id == item.notiAnlstId).iconImg" alt="캐릭터 이미지" class="Announce__analysis--character" width="40px" height="40px">
                            //         <span class="txt__title">&nbsp;&nbsp;{{mock.notiTable.notiAnlstList.find((e) => e.id == item.notiAnlstId).anlstNickNm}}</span>
                            var iconImg = '';
                            var anlstNickNm = '';

                            if (mock.notiTable.notiAnlstList.findIndex((e) => e.id == notiList[i].notiAnlstId) != -1) {
                                iconImg = mock.notiTable.notiAnlstList.find((e) => e.id == notiList[i].notiAnlstId).iconImg;
                                anlstNickNm = mock.notiTable.notiAnlstList.find((e) => e.id == notiList[i].notiAnlstId).anlstNickNm;
                            }

                            var regstFinDt = this.$moment(notiList[i].regstFinDt);
                            var bddprFinDt = this.$moment(notiList[i].bddprFinDt);

                            //오늘 날짜 여부
                            if (regstFinDt.format('YYYY-MM-DD') == today.format('YYYY-MM-DD') && regstFinDt.isAfter(today)) {
                                notiList[i].isRegDt = true;
                            } else {
                                notiList[i].isRegDt = false;
                            }
                            if (bddprFinDt.format('YYYY-MM-DD') == today.format('YYYY-MM-DD') && bddprFinDt.isAfter(today)) {
                                notiList[i].isBddprDt = true;
                            } else {
                                notiList[i].isBddprDt = false;
                            }

                            var obj = {
                                id: notiList[i].id,
                                notiNo: notiList[i].notiNo,
                                notiSeq: notiList[i].notiSeq,
                                baseAmt: notiList[i].baseAmt,
                                notiNm: notiList[i].notiNm,
                                anlsBddprAmt: anlsBddprAmt,
                                anlsBddprRt: anlsBddprRt,
                                custNotiId: custNotiId,
                                notiId: notiList[i].notiId,
                                custBizId: notiList[i].custBizId,
                                anlstChoiceFlag: anlstChoiceFlag,
                                custNotiAnlsId: custNotiAnlsId,
                                iconImg: iconImg,
                                anlstNickNm: anlstNickNm,
                                // iconImg: require(iconImg),
                                notiAnlstId: notiList[i].notiAnlstId,
                                rpsntOrgnzCd: notiList[i].rpsntOrgnzCd,
                                bddprFinDt: notiList[i].bddprFinDt,
                                regstFinDt: notiList[i].regstFinDt,
                                opbdDt: notiList[i].opbdDt,
                                notiStatus: notiList[i].notiStatus,
                                regstVisibleYn: notiList[i].regstVisibleYn,
                                rpsntOrgnzCdNm: notiList[i].rpsntOrgnzCdNm,
                                tlwtOrgnzNm: notiList[i].tlwtOrgnzNm,
                                bddprConfYn: notiList[i].bddprConfYn,
                                bddprRegstYn: notiList[i].bddprRegstYn,
                                bddprStrtDt: notiList[i].bddprStrtDt,
                                licnsNmConcat: notiList[i].licnsNmConcat,
                                areaNmConcat: notiList[i].areaNmConcat,
                                isRegDt: notiList[i].isRegDt,
                                isBddprDt: notiList[i].isBddprDt,
                                noticeMainLicnsList: notiList[i].noticeMainLicnsList,
                            }

                            mock.notiTable.noti.push(obj);

                            // if (this.$moment().format('YYYYMMDDHHmmss') <= this.$moment(notiList[i].opbdDt).format('YYYYMMDDHHmmss')) {
                            //
                            //     mock.notiTable.noti.push(obj);
                            // }
                        }
                        this.refactCustNotiList();

                        this.$parent.$parent.isLoadingBar(false);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$parent.$parent.isLoadingBar(false);
                    });
            },
            copyNotiNo(value) {
                this.$copyText(value);
                this.snackbar = true;
                this.copyText = value;
                this.$stopEventBubble();

                // this.$toast('HelloWorld', { position: "bottom-right" });

                this.$toast.success("공고번호를 복사했습니다.");

                // alert("공고번호를 복사했습니다.");
            },
            copyAnlsBddprAmt(value) {
                this.$copyText(value);
                this.snackbar = true;
                this.copyText = value;
                this.$stopEventBubble();

                this.$toast.success("분석제시가를 복사했습니다.",);

                // alert("분석투찰금액을 복사했습니다.");
            },
            click($event) {
                //상세 검색 아이콘 클릭 > 필터 on
                if ($event.target.closest('.analysis__search--detaile') !== null) {
                    document.querySelector('.filter').classList.toggle('on');
                } else if ($event.target.closest('.analysis__search--detaile') === null) { // 필터 영역 제외한 부분 클릭시 on 클래스 제거
                    if ($event.target.closest('.filter__wrap') === null) {
                        document.querySelector('.filter').classList.remove('on');
                    }
                }
                //필터 영역의 끄기 버튼
                if ($event.target === document.querySelector('.btn-close')) {
                    document.querySelector('.filter').classList.remove('on');
                }
                if ($event.target === document.querySelector('.btn-close')) {
                    document.querySelector('.filter').classList.remove('on');
                }
                //셀렉트 박스 dud
                if (document.querySelector('.select-box__sel').classList.contains('on') && $event.target !== document.querySelector('.select-box__sel')) {
                    document.querySelector('.select-box__sel').classList.remove('on');
                }
                //로그인 바 제외한 영역 클릭시 on 클래스 제거
                // if (document.querySelector('.login-bar--wrap').classList.contains('on')) {
                //     document.querySelector('.login-bar--wrap').classList.remove('on');
                // }
            },
            updateCustNotiRegstStatus(item) {
                var param = {
                    id: item.id,
                    custBizId: item.custBizId,
                    bddprRegstYn: item.bddprRegstYn == 'Y' ? 'N' : 'Y',
                    mobileYn: 'N'
                };

                this.$post('/api/noti/updateCustNotiRegstStatus', param)
                    .then(res => {
                        if (res.code == "OK") {
                            item.bddprRegstYn = item.bddprRegstYn == 'Y' ? 'N' : 'Y';
                        }

                        // $('#check'+item['id']).classList.add("btn-active");
                        // this.$router.go();
                    })
                    .catch(error => console.log(error));
                this.$stopEventBubble();
            },
            updateCustNotiBddprStatus(item) {
                if (item.anlstChoiceFlag != 'Y') {
                    this.$toast.error('분석제시가 확인 후, 투찰완료처리 가능합니다.');
                    return;
                }

                var param = {
                    id: item.id,
                    custBizId: item.custBizId,
                    bddprConfYn: item.bddprConfYn == 'Y' ? 'N' : 'Y',
                    mobileYn: 'N'
                };


                this.$post('/api/noti/updateCustNotiBddprStatus', param)
                    .then(res => {
                        if (res.code == "OK") {
                            item.bddprConfYn = item.bddprConfYn == 'Y' ? 'N' : 'Y';
                        }

                        // $('#check'+item['id']).classList.add("btn-active");
                        // this.$router.go();
                    })
                    .catch(error => console.log(error));
                this.$stopEventBubble();
            },
            confirmAmt(item) {
                const currentDate = new Date();

                const bddprStrtDt = new Date(item.bddprStrtDt);
                if (item.regstVisibleYn == 'Y' && item.bddprRegstYn == 'N') {
                    this.isPopRegstConfirm = true;
                } else if (currentDate > bddprStrtDt) {
                    this.selNoti = item;
                    this.isPopBddprConfirm = true;
                } else {
                    var year = bddprStrtDt.getFullYear();
                    var month = ('0' + (bddprStrtDt.getMonth() + 1)).slice(-2);
                    var day = ('0' + bddprStrtDt.getDate()).slice(-2);
                    var hours = ('0' + bddprStrtDt.getHours()).slice(-2);
                    var minutes = ('0' + bddprStrtDt.getMinutes()).slice(-2);

                    var week = ['일', '월', '화', '수', '목', '금', '토'];
                    var dayOfWeek = week[new Date(bddprStrtDt).getDay()];

                    var dateString = year + '.' + month + '.' + day + '(' + dayOfWeek + ') ' + hours + ':' + minutes;

                    this.bddprDt = dateString;
                    this.isPopAnlsRestrain = true;
                }

                this.$stopEventBubble();
            },
            anlsAmtChoice() {
                const currentDate = new Date();
                const bddprFinDt = new Date(this.selNoti.bddprFinDt);

                if (currentDate > bddprFinDt) {
                    this.isPopBddprConfirm = false;
                    this.$toast.success("투찰 마감일이 지난 공고입니다.");
                    return;
                }

                var praram = {
                    id: this.selNoti.custNotiAnlsId,
                    // anlsBddprAmt: this.selNoti.anlsBddprAmt,
                    // anlsBddprRt: this.selNoti.anlsBddprRt,
                    custNotiId: this.selNoti.custNotiId,
                    notiId: this.selNoti.notiId,
                    custBizId: this.selNoti.custBizId,
                    anlstChoiceFlag: 'Y',
                    mobileYn: 'N'
                };

                this.$post('/api/noti/updateAnlsChoiceFlag', praram)
                    .then(res => {
                        if (res.code == "OK") {
                            $('#radio' + this.selNoti.custNotiId).prop("checked", true);
                            this.selNoti.anlsBddprAmt = res.anlsBddprAmt;
                            this.selNoti.anlstChoiceFlag = 'Y';
                            this.copyAnlsBddprAmt(this.selNoti.anlstChoiceFlag=='Y' ? this.selNoti.anlsBddprAmt.toString() : '0');
                        } else if(res.errorCode == 3){
                            this.$toast.error(res.msg);
                            this.selNoti.anlsBddprAmt = 0;
                        } else {
                            this.selNoti.anlsBddprAmt = 0;
                        }
                        this.isPopBddprConfirm = false;
                        // this.$router.go();
                    })
                    .catch(error => {
                            console.log(error);
                            this.isPopBddprConfirm = false;
                        }
                    );
            }
        }
    }


</script>
<!--<style src="./Analysis.scss" lang="scss" scoped />-->