<template>
    <div class="edit__area manage-info pre-year tooltip">
        <div class="edit__area--head">
            <div>
            <p class="edit__area--title">경영/면허 정보</p>
            <div class="edit-btn__group">
                <a href="#" onclick="return false" class="btn-basic btn-cta edit--btn manage-info--btn" v-show="isEditLicnsInfo" :class="isProcessing ? 'loading-box' : ''" @click="saveCustBiz()">
                    <LoadingSpinner v-show="isProcessing"></LoadingSpinner>
                    {{isProcessing ? 'ㅤ' : '저장'}}
                </a>
                <a href="#" onclick="return false" class="btn-basic btn-line-cw edit--btn manage-info--btn" v-show="!isEditLicnsInfo" @click="modifyLicns()">수정</a>
                <a href="#" onclick="return false" class="btn-basic btn-line-cw edit--btn manage-info--btn license" @click="$goMenu('counselBoard')">면허변경요청</a>
            </div>
                <p class="manage-info--help" :style="{ display: manageHelpText.length > 0 ? '' : 'none !important' }">{{manageHelpText}}</p>
            </div>
        </div>

        <!-- 경영상태 -->
        <div class="manage-info--tooltip manage-info--tooltip--sub-tit">
            <div class="pre-year__group">
                <span class="manage-info--tit pre-year--tit">① 경영 정보상태</span>
                <div class="tooltip__group manage">
                    <span class="tooltip__box manage-info--box">?</span>
                    <div class="tooltip--txt">{{tooltipTxt1}}</div>
                </div>
            </div>
            <div class="pre-year__group help recom-announce" v-show="isBefLmtAmtYn == false">
                <div class="user-info__list cost"  >
                    <div class="user-info__list--head pre-year--head" >
                        <h4 class="h4">더 정확한 맞춤공고 <span class="line-break">서비스 받기</span></h4>
                    </div>
                    <p class="cost--list pre-year--list"  @click="$refs.BefLicnsLayerPop.isPopBefLicnsPop = true;" >
                        2022년 기준 <span class="line-break">내 최대 참여 가능한 공고 금액 알아보기 <i class="ic--arrow"></i></span>
                    </p>
                </div>
            </div>
        </div>
        <div class="grid__container edit__list manage-info__list">
            <div class="grid__group edit__list--item">
                <p class="grid__cell txt--title">부채비율</p>
                <p class="grid__cell txt--sub" v-show="!isEditLicnsInfo">{{mock.custBiz.debtRate != null ? mock.custBiz.debtRate+'%' : '-'}}</p>
                <!-- 부채비율 수정 -->
                <div class="debt--input input edit--input grid__cell manage-info--percent" v-show="isEditLicnsInfo">
                    <input type="text" placeholder="부채비율을 입력해 주세요." v-model="debtRateComputed">
                </div>
                <!-- /부채비율 수정 -->
            </div>
            <div class="grid__group edit__list--item">
                <p class="grid__cell txt--title">유동비율</p>
                <p class="grid__cell txt--sub" v-show="!isEditLicnsInfo">{{mock.custBiz.crasRate != null ? mock.custBiz.crasRate+'%' : '-'}}</p>
                <!-- 유동비율 수정 -->
                <div class="cras--input input edit--input grid__cell manage-info--percent" v-show="isEditLicnsInfo">
                    <input type="text" placeholder="유동비율을 입력해 주세요." v-model="crasRateComputed">
                </div>
                <!-- /유동비율 수정 -->
            </div>
            <div class="grid__group edit__list--item credit-estimat">
                <div class="manage-info--tooltip grid__cell">
                    <span class="txt--title">신용평가</span>
                    <div class="tooltip__group credit">
                        <span class="tooltip__box">?</span>
                        <div class="tooltip--txt">공공기관 입찰용으로 발급받은 신용평가등급확인서로만 사용할 수 있습니다.</div>
                    </div>
                </div>
                <p class="grid__cell txt--sub" v-show="!isEditLicnsInfo">{{mock.custBiz.custPrfsh !=null ? mock.custBiz.custPrfsh.prfshEtcNm :''}}
                    <span class="txt credit" v-show="mock.custBiz.custPrfsh !=null && mock.custBiz.custPrfsh !=null && mock.custBiz.custPrfsh.proofRnwlDe != null">(~{{ mock.custBiz.custPrfsh !=null  ? $moment(mock.custBiz.custPrfsh.proofRnwlDe).format('YYYY.MM.DD') : ''}} ㅣ {{ mock.custBiz.custPrfsh !=null ? mock.custBiz.custPrfsh.prfshOrgnzNm : ''}})</span>
                    <span class="txt credit" v-show="!(mock.custBiz.custPrfsh !=null && mock.custBiz.custPrfsh !=null && mock.custBiz.custPrfsh.proofRnwlDe != null)">ㅤ</span>
                </p>

                <!-- 신용평가 수정 -->
                <div class="input edit--input grid__cell manage-info--credit" v-show="isEditLicnsInfo">
                    <div class="credit__group">
                        <div class="select-box">
                            <button class="select-box__sel" id="cdleStSelectBox">{{cdltText}}</button>
                            <ul class="select-box__list" id="cdleStSelectBoxItem">
                                <li>
                                    <button type="button">해당사항 없음</button>
                                </li>
                                <li v-for="cdlt in cdltStList" v-bind:key="cdlt.id" :value="cdlt.id">
                                    <button type="button" :value="cdlt.id">{{cdlt.cdltGrd}}</button>
                                </li>
                            </ul>
                        </div>
                        <div class="select-box" v-show="!(selGrd == '')">
                            <button class="select-box__sel" id="cdleStOrgSelectBox">{{orgnzText}}</button>
                            <ul class="select-box__list" id="cdleStOrgSelectBoxItem">
                                <li>
                                    <button type="button">해당사항 없음</button>
                                </li>
                                <li v-for="orgnz in cdltStOrgList" v-bind:key="orgnz.id" :value="orgnz.comnCdNm">
                                    <button type="button" :value="orgnz.comnCdNm">{{orgnz.comnCdNm}}</button>
                                </li>
                            </ul>
                        </div>
                        <div class="select-box disable" v-show="selGrd == ''">
                            <button class="select-box__sel">발급기관을 선택해 주세요.</button>
                        </div>
                        <v-date-picker v-model="cdltStDe">
                            <template v-slot="{ inputValue, togglePopover }">
                                <div class="input edit--input grid__cell manage-info--calendar btn btn-calendar disable" v-show="!selGrd == ''"
                                     @click="togglePopover">
                                    <input
                                            class="input edit-pop--input manage--input"
                                            placeholder="만료일을 선택해 주세요."
                                            :value="inputValue"
                                    />
                                </div>
                                <div class="input edit--input grid__cell manage-info--calendar btn btn-calendar disable" v-show="selGrd == ''">
                                    <input type="text" class="disable" placeholder="만료일을 선택해주세요.">
                                </div>
                            </template>
                        </v-date-picker>
                    </div>
                </div>
                <!-- /신용평가 수정 -->
            </div>
        </div>
        <!-- /경영상태 -->

        <!-- 면허 정보 -->
        <p class="manage-info--tit">② 면허 정보(총 {{modifyCustLicnsList.length}}개) <span class="manage-info--sub">*면허별로 정보를 입력해 주세요.</span></p>
        <div class="recom-announce">
            <!-- 면허 종류 슬라이드 -->
            <div class="tok-slide__wrap user-info__slide">
                <div class="tok-slide__container">
                    <ul class="tok-slide--list user-info__slide--list">
                        <li class="tok-slide__item user-info__slide--item" :class="modifySelectLicns == licns ? 'active' : '' " v-for="licns in modifyCustLicnsList" v-bind:key="licns.id">
                            <a href="#" onclick="return false" @click="clickLicns(licns)">{{licns.licnsNm}}</a>
                        </li>
                    </ul>
                </div>
                <ul class="tok-slide__arrow">
                    <li class="tok-slide__arrow--item pre user-info__slide--arrow--item manage-info--arrow" @click="previos">previos</li>
                    <li class="tok-slide__arrow--item next user-info__slide--arrow--item manage-info--arrow" @click="next">next</li>
                </ul>
            </div>
            <!-- /면허 종류 슬라이드 -->
        </div>

        <div class="grid__container edit__list manage-info__list">
            <div class="grid__group edit__list--item">
                <p class="grid__cell txt--title">면허명</p>
                <div class="grid__cell txt--sub manage-info-license">
                    <div class="bdg-lic" v-show="modifySelectLicns.mainLicnsCd == null && modifySelectLicns.licnsNm != null&& modifySelectLicns.licnsNm.indexOf('(대)') != -1">
                        <!-- 주력 main / 대업종 big-->
                        <i class="bdg-lic--ic big">대업종</i>
                    </div>
                    <div class="bdg-lic" v-show="modifySelectLicns.mainLicnsCd != null">
                        <!-- 주력 main / 대업종 big-->
                        <i class="bdg-lic--ic main" >주력</i>
                    </div>
                    <span class="">{{modifySelectLicns.licnsNm}}</span>
                </div>
            </div>
            <div class="grid__group edit__list--item">
                <div class="manage-info--tooltip grid__cell manage-info--cell">
                    <span class="txt--title">3년 실적</span>
                    <div class="tooltip__group year3">
                        <span class="tooltip__box">?</span>
                        <div class="tooltip--txt">{{tooltipTxt2}}</div>
                    </div>
                </div>
                <p class="grid__cell txt--sub" v-show="!isEditLicnsInfo">{{selectLicns.y3AcrsltAmt | currency}} {{selectLicns.y3AcrsltAmt != null && selectLicns.y3AcrsltAmt != '-' ? '원': ''}}</p>

                <!-- 3년 실적 수정 -->
                <div class="input edit--input grid__cell manage-info--won" v-show="isEditLicnsInfo">
                    <input type="text" placeholder="0" v-model="y3AcrsltAmtComputed">
                </div>
                <!-- /3년 실적 수정 -->
            </div>
            <div class="grid__group edit__list--item">
                <div class="manage-info--tooltip grid__cell manage-info--cell">
                    <span class="txt--title">5년 실적</span>
                    <div class="tooltip__group year5">
                        <span class="tooltip__box">?</span>
                        <div class="tooltip--txt">{{tooltipTxt3}}</div>
                    </div>
                </div>
                <p class="grid__cell txt--sub" v-show="!isEditLicnsInfo">{{selectLicns.y5AcrsltAmt | currency}} {{selectLicns.y5AcrsltAmt != null && selectLicns.y5AcrsltAmt != '-' ? '원': ''}}</p>

                <!-- 5년 실적 수정 -->
                <div class="input edit--input grid__cell manage-info--won" v-show="isEditLicnsInfo">
                    <input type="text" placeholder="0" v-model="y5AcrsltAmtComputed">
                </div>
                <!-- /5년 실적 수정 -->
            </div>
            <div class="grid__group edit__list--item">
                <div class="tooltip manage-info--tooltip grid__cell manage-info--cell">
                    <span class="txt--title">추가 실적</span>
                    <div class="tooltip__group perform">
                        <span class="tooltip__box">?</span>
                        <div class="tooltip--txt">협회에 등록되지는 않았지만 최종 준공이 완료된 공사 실적 합계액입니다.</div>
                    </div>
                </div>
                <p class="grid__cell txt--sub" v-show="!isEditLicnsInfo">{{selectLicns.lcettSuplmtAcrsltAmt | currency}}{{selectLicns.lcettSuplmtAcrsltAmt != null && selectLicns.lcettSuplmtAcrsltAmt
                    != '-' ? '원': ''}}</p>

                <!-- 추가 실적 수정 -->
                <div class="input edit--input grid__cell manage-info--won" v-show="isEditLicnsInfo">
                    <input type="text" placeholder="0" v-model="lcettSuplmtAcrsltAmtComputed">
                </div>
                <!-- /추가 실적 수정 -->
            </div>
            <div class="grid__group edit__list--item">
                <div class="tooltip manage-info--tooltip grid__cell manage-info--cell">
                    <span class="txt--title">시공능력평가액</span>
                    <div class="tooltip__group capa">
                        <span class="tooltip__box">?</span>
                        <div class="tooltip--txt">{{tooltipTxt4}}</div>
                    </div>
                </div>
                <p class="grid__cell txt--sub" v-show="!isEditLicnsInfo">{{selectLicns.cswkCapaEvltnAmt | currency}}{{selectLicns.cswkCapaEvltnAmt != null && selectLicns.cswkCapaEvltnAmt != '-' ? '원':
                    ''}}</p>

                <!-- 시공능력평가액 수정 -->
                <div class="input edit--input grid__cell manage-info--won" v-show="isEditLicnsInfo">
                    <input type="text" placeholder="0" v-model="cswkCapaEvltnAmtComputed">
                </div>
                <!-- /시공능력평가액 수정 -->
            </div>
            <div class="grid__group edit__list--item">
                <div class="tooltip manage-info--tooltip grid__cell manage-info--cell">
                    <span class="txt--title">영업기간</span>
                    <div class="tooltip__group capa">
                        <span class="tooltip__box">?</span>
                        <div class="tooltip--txt">{{tooltipTxt5}}</div>
                    </div>
                </div>
                <p class="grid__cell txt--sub" v-show="!isEditLicnsInfo && selectLicns.bsnRegstDe != null">{{selectLicns.bsnRegstDe}}<span v-show="selectLicns.bsnRegstDe != '-'"> ({{ selectLicns.bsnPerdCd | bsnPerdText}})</span>
                </p>
                <p class="grid__cell txt--sub" v-show="!isEditLicnsInfo && selectLicns.bsnRegstDe == null">-</p>
                <!-- 영업기간 수정 -->
                <v-date-picker v-model="bsnRegstDe"  v-show="isEditLicnsInfo">
                    <template v-slot="{ inputValue, togglePopover }">
                        <div class="input edit--input grid__cell btn-calendar" v-show="isEditLicnsInfo"
                             @click="togglePopover">
                            <input
                                    class="input edit-pop--input manage--input"
                                    placeholder="영업기간을 선택해 주세요."
                                    :value="inputValue"
                            />
                        </div>
                    </template>
                </v-date-picker>
                <!-- /영업기간 수정 -->
            </div>
        </div>
        <!-- /면허 정보 -->

        <!-- 최대 참여 가능 공고 금액 -->
        <div class="tooltip manage-info--tooltip manage-info--tooltip--sub-tit">
            <span class="manage-info--tit">③ 최대 참여 가능 공고 금액</span>
            <div class="tooltip__group max-amount">
                <span class="tooltip__box">?</span>
                <div class="tooltip--txt">경영상태와 실적을 바탕으로 계산된 금액입니다.</div>
            </div>
        </div>
        <div class="max-amount__wrap max-amount edit__group" v-show="selectLicns.custPrtcAmtList != null && selectLicns.custPrtcAmtList.length > 0">
            <ul class="tab02 max-amount__tab">
                <li class="tab02--list max-amount__tab--list" :class="isPopPrtcAmtTab1 ? 'active' : ''">
                    <a href="#" onclick="return false" @click="isPopPrtcAmtTab1 = true">현재기준</a>
                </li>
                <li class="tab02--list max-amount__tab--list" :class="!isPopPrtcAmtTab1 ? 'active' : ''" v-show="isBefLmtAmtYn">
                    <a href="#" onclick="return false" @click="isPopPrtcAmtTab1 = false">{{avlblPerdExpyDe}} 이전 기준</a>
                </li>
            </ul>
            <div class="max-amount__unit">
                <!-- 현재기준
                수의 계약 공고 참여 가능할 경우 not-candidate 클래스명 추가-->
                <div class="grid__wrap max-amount__grid" v-show="isPopPrtcAmtTab1" :class="isNotCandidate ?  'not-candidate' : ''">
                    <div class="not-candidate--cont"><p>수의 계약 공고 참여 가능<br>(적격심사 비대상)</p></div>
                    <div class="grid__container" v-show="selectLicns.custPrtcAmtList != null">
                        <ul class="grid__group head max-amount__grid--head">
                            <li class="grid__cell max-amount__grid--cell">발주처</li>
                            <li class="grid__cell max-amount__grid--cell">기초금액</li>
                        </ul>
                        <ul class="grid__group body max-amount__grid--body" v-for="custPrtcAmt in selectLicns.custPrtcAmtList" v-bind:key="custPrtcAmt.id">
                            <li class="grid__cell max-amount__grid--cell">{{custPrtcAmt.cfmtUndwrtStIdNm}}</li>
                            <li class="grid__cell max-amount__grid--cell">{{custPrtcAmt.lmtAmt != null ? ('~' + custPrtcAmt.lmtAmt.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + '원') : ('-') }}</li>
                        </ul>
                    </div>
                </div>
                <!-- /현재기준 -->

                <!-- 특정 시점 기준 -->
                <div class="grid__wrap max-amount__grid" v-show="!isPopPrtcAmtTab1 && isBefLmtAmtYn" :class="isBefNotCandidate ?  'not-candidate' : ''">
                    <div class="not-candidate--cont"><p>수의 계약 공고 참여 가능<br>(적격심사 비대상)</p></div>
                    <div class="grid__container" v-show="selectLicns.custPrtcAmtList">
                        <ul class="grid__group head max-amount__grid--head">
                            <li class="grid__cell max-amount__grid--cell">발주처</li>
                            <li class="grid__cell max-amount__grid--cell">기초금액</li>
                        </ul>
                        <ul class="grid__group body max-amount__grid--body" v-for="custPrtcAmt in selectLicns.custPrtcAmtList" v-bind:key="custPrtcAmt.id">
                            <li class="grid__cell max-amount__grid--cell">{{custPrtcAmt.cfmtUndwrtStIdNm}}</li>
                            <li class="grid__cell max-amount__grid--cell">{{custPrtcAmt.befLmtAmt != null ? ('~' + custPrtcAmt.befLmtAmt.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + '원') : ('-')
                                }}
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- /특정 시점 기준 -->
            </div>
        </div>
        <!-- /최대 참여 가능 공고 금액 -->
        <!-- 면허 정보 없을 때 >  수정버튼 누를 시 팝업 -->
        <div class="popup__wrap licns-empty" :class="isPopNotExistCustLicns ? 'active' : ''">
          <div class="popup__item--container">
            <div class="popup__item--group">
              <div class="popup__item--body no-res licns-empty--body">
                <div class="no-res--img"></div>
                <p class="title">죄송합니다.</p>
                <p class="sub">고객님의 면허 정보가 파악되지 않았습니다.<br>정확한 정보 확인을 위해<br>고객센터 1670-0508로<br>연락 부탁드립니다.</p>
              </div>
            </div>
            <div class="popup__button--wrap single">
              <a href="#" onclick="return false" class="btn-basic btn-cta popup__close" @click="isPopNotExistCustLicns = false">닫기</a>
            </div>
          </div>
        </div>
        <!-- /면허 정보 없을 때 >  수정버튼 누를 시 팝업 -->
        <BefLicnsLayerPop ref="BefLicnsLayerPop"></BefLicnsLayerPop>
    </div>
</template>
<script>
    import mock from '../mock';
    import * as _ from 'lodash'
    import LoadingSpinner from '@/components/common/LoadingSpinner.vue';
    import BefLicnsLayerPop from '@/pages/V2/Popup/BefLicnsLayerPop'

    export default {
        data() {
            return {
                mock,
                isEditLicnsInfo: false, // 면허 수정 활성 여부

                cdltStList: [], //신용평가 목록
                cdltStOrgList: [], //발급기관 목록

                custLicnsList: [],     //고객 면허 목록
                modifyCustLicnsList: [],     //수정 고객 면허 목록
                selectLicns: {},  //선택 라이센스
                modifySelectLicns: {},  //선택 라이센스
                debtRate: '',   //부채비율
                crasRate: '',   //유동비율
                y3AcrsltAmt: '',    //3년실적
                y5AcrsltAmt: '',    //5년 실적
                lcettSuplmtAcrsltAmt: '',   //추가실적
                cswkCapaEvltnAmt: '',   //시공능력평가금액
                cdltStDe: '',   //신용평가 만료일
                bsnRegstDe: '',     //영업기간

                selGrd: '', //선택등급
                selOrgnz: '', // 선택 발급 기관

                orgnzText: '발급기관을 선택해 주세요.', // 선택 발급 기관
                cdltText: '등급을 선택해 주세요.',   //선택 등급

                isProcessing: false, //클릭여부

                isPopPrtcAmtTab1: true,    //최대 참여 금액 표 팝업 탭1 활성화여부
                isBefLmtAmtYn: false, //이전 정보 여부
                isBefNotCandidate: false, //이전 수의 여부
                isNotCandidate: false, //수의 여부

                tooltipList: [], //툴팁리스트
                tooltipTxt1: '',    //경영상태 툴팁
                tooltipTxt2: '',    //3년실적 툴팁
                tooltipTxt3: '',    //5년실적 툴팁
                tooltipTxt4: '',    //시공평가액 툴팁
                tooltipTxt5: '',    //영업기간 툴팁
                tooltipActive : false,

                avlblPerdExpyDe: '',  // 최대 참여 금액 기준일

                manageHelpText: '',  //경상변경 문구 텍스트
                isPopNotExistCustLicns: false, //면허가 없을 때 수정 버튼 누를때 팝업 여부
            }
        },
        components: {
            LoadingSpinner,
            BefLicnsLayerPop
        },
        computed: {
            debtRateComputed: {
                get() {
                    try {
                        return this.debtRate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    } catch {

                        return '';
                    }
                },
                set(value) {
                    if (value == '') {
                        this.debtRate = '';
                    } else {
                        this.debtRate = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            crasRateComputed: {
                get() {
                    try {
                        return this.crasRate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    } catch {
                        return '';
                    }
                },
                set(value) {
                    if (value == '') {
                        this.crasRate = '';
                    } else {
                        this.crasRate = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            y3AcrsltAmtComputed: {
                get() {

                    return this.y3AcrsltAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    if (value == '') {
                        this.y3AcrsltAmt = 0;
                    } else {
                        this.y3AcrsltAmt = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            y5AcrsltAmtComputed: {
                get() {
                    return this.y5AcrsltAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    if (value == '') {
                        this.y5AcrsltAmt = 0;
                    } else {
                        this.y5AcrsltAmt = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            cswkCapaEvltnAmtComputed: {
                get() {
                    return this.cswkCapaEvltnAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    if (value == '') {
                        this.cswkCapaEvltnAmt = 0;
                    } else {
                        this.cswkCapaEvltnAmt = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
            lcettSuplmtAcrsltAmtComputed: {
                get() {
                    return this.lcettSuplmtAcrsltAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    if (value == '') {
                        this.lcettSuplmtAcrsltAmt = 0;
                    } else {
                        this.lcettSuplmtAcrsltAmt = parseFloat(value.replace(/,/g, ''));
                    }
                }
            },
        },
        watch: {
            selectLicns() {
                if (this.selectLicns.custPrtcAmtList.length > 0) {
                    if (this.selectLicns.custPrtcAmtList[0].avlblPerdExpyDe != null &&
                        this.$moment().isAfter(this.$moment(this.selectLicns.custPrtcAmtList[0].avlblPerdExpyDe)) > 0 &&
                        this.selectLicns.custPrtcAmtList[0].befLmtAmt != null) {
                        this.isBefLmtAmtYn = true;
                        this.avlblPerdExpyDe = this.$moment(this.selectLicns.custPrtcAmtList[0].avlblPerdExpyDe).format('MM월 DD일');
                        if (this.selectLicns.custPrtcAmtList[0].rateGrd == 'C') {
                            this.isNotCandidate = true;
                        } else {
                            this.isNotCandidate = false;
                        }
                        if (this.selectLicns.custPrtcAmtList[0].befRateGrd == 'C') {
                            this.isBefNotCandidate = true;
                        } else {
                            this.isBefNotCandidate = false;
                        }
                    } else {
                        if (this.selectLicns.custPrtcAmtList[0].rateGrd == 'C') {
                            this.isNotCandidate = true;
                        } else {
                            this.isNotCandidate = false;
                        }
                    }
                }
                if(this.tooltipList.length > 0){

                    this.changeTooltipText();
                }
            },
            selGrd(newValue) {
                if (newValue == '') {
                    this.selOrgnz = '';
                    this.cdltStDe = '';
                }
            },
        },
        filters: {
            bsnPerdText: function (value) {

                if (value == '0') {
                    return '1년미만';
                } else if (value == '1') {
                    return '1년~3년';
                } else if (value == '2') {
                    return '3년~5년';
                } else if (value == '3') {
                    return '5년이상';
                }
                return '';
            },
        },
        mounted() {
            this.loginInfo = JSON.parse(localStorage.getItem("custInfo"));

            if (this.loginInfo != null) {
                //면허 정보
                this.getCustLicnsList();
            }

            //신용평가 기준 목록
            this.getCdltSdList();

            //발급기관 목록
            this.getCdltStOrgnz();

            // this.tooltipScript();

            if(this.loginInfo != null){
                //홈 팝업 정보
                this.getHomePopupList();
            }


        },
        methods: {
            /** 툴팁 리스트 가져오기*/
            getTooltipList() {
                var stringList = [];
                for (var i in this.custLicnsList) {
                    stringList.push(this.custLicnsList[i].licnsCd)
                }

                var param = {
                    licnsCdList: stringList,
                    tooltipCdList: [1, 2, 3, 4, 5]
                };

                this.$post('/api/comn/tooltipList', param)
                    .then(res => {
                        this.tooltipList = res.tooltipList;
                        this.changeTooltipText();
                    })
                    .catch(error => console.log(error));
            },
            /** 툴팁 텍스트 변경*/
            changeTooltipText() {
                var index1 = this.tooltipList.findIndex((e) => e.licnsCd == this.modifySelectLicns.licnsCd && e.tooltipCd == '1');
                var index2 = this.tooltipList.findIndex((e) => e.licnsCd == this.modifySelectLicns.licnsCd && e.tooltipCd == '2');
                var index3 = this.tooltipList.findIndex((e) => e.licnsCd == this.modifySelectLicns.licnsCd && e.tooltipCd == '3');
                var index4 = this.tooltipList.findIndex((e) => e.licnsCd == this.modifySelectLicns.licnsCd && e.tooltipCd == '4');
                var index5 = this.tooltipList.findIndex((e) => e.licnsCd == this.modifySelectLicns.licnsCd && e.tooltipCd == '5');

                if (index1 !=  -1) {
                    this.tooltipTxt1 = this.tooltipList[index1].contents
                } else {
                    this.tooltipTxt1 = '22년 경영상태동의확인서 적용입니다.';
                }
                if (index2 !=  -1) {
                    this.tooltipTxt2 = this.tooltipList[index2].contents
                } else {
                    this.tooltipTxt2 = '최근 22년, 21년, 20년도의 최종 준공 완료된 공사 실적 합계액입니다.';
                }
                if (index3 !=  -1) {
                    this.tooltipTxt3 = this.tooltipList[index3].contents
                } else {
                    this.tooltipTxt3 = '최근 22년, 21년, 20년, 19년, 18년도의 최종 준공 완료된 공사 실적 합계액입니다.';
                }
                if (index4 !=  -1) {
                    this.tooltipTxt4= this.tooltipList[index4].contents
                } else {
                    this.tooltipTxt4 = '업체가 공사를 수행할 수 있는 능력을 금액으로 환산한 것으로 현재 23년 시공능력평가액 적용됩니다.';
                }
                if (index5 !=  -1) {
                    this.tooltipTxt5 = this.tooltipList[index5].contents
                } else {
                    this.tooltipTxt5 = '해당 면허의 영업시작일로 공사업 등록증 또는 경영상태등의확인서에서 확인할 수있습니다.';
                }
                if(!this.tooltipActive) {
                    this.tooltipScript();
                    this.tooltipActive = true;
                }
            },
            //홈 팝업 정보
            getHomePopupList() {
                this.$get('/api/home/homePopupList')
                    .then(res => {
                        if(res.code == 'OK'){
                            var acrsltList = res.acrsltList;
                            var manageStatList = res.manageStatList;
                            var cswkCapaEvltnList = res.cswkCapaEvltnList;
                            var manageList = res.manageList;

                            if(acrsltList != null){
                                this.manageHelpText = '*일반면허 또는 전문면허를 보유한 고객님은 3년실적, 5년실적을 변경해주세요';
                            } else if(manageStatList != null){
                                this.manageHelpText = '*일반면허 또는 전문면허를 보유한 고객님은 부채비율, 유동비율을 변경해주세요';
                            } else if(cswkCapaEvltnList != null){
                                this.manageHelpText = '*일반면허 또는 전문면허를 보유한 고객님은 시공능력평가액을 변경해주세요';
                            } else if(manageList != null){
                                this.manageHelpText = '*전기/소방/통신 면허를 보유한 고객님은 부채비율, 유동비율, 3년실적, 5년실적을 변경해주세요';
                            }

                        }
                    })
                    .catch(error => {
                            console.log(error);
                        }
                    );
            },
            //툴팁 스크립트
            tooltipScript() {

//툴팁 수정 (before after 선택자에 내용 x)
                let toolTip01 = document.querySelectorAll('.tooltip');
                if (toolTip01.length > 0) {
                    toolTip01.forEach(element => {
                        element.addEventListener('click', function (event) {
                            toolTipClass01(event.target);
                            event.stopPropagation(); // 이벤트 전파 중단
                        });
                    });
                }

                function toolTipClass01(target) {
                    let parent = target.parentElement;
                    while (parent) {
                        if (parent && parent.classList.contains('tooltip')) {
                            parent.classList.toggle('active');
                            break;
                        }
                        parent = parent.parentElement; // 부모 요소 업데이트
                    }
                }

                document.addEventListener('click', function (event) {
                    const clickedElement = event.target;
                    if (!clickedElement.classList.contains('tooltip__box')) {
                        toolTip01.forEach(element => {
                            element.classList.remove('active');
                        });
                    }
                });


//툴팁 (before after 선택자에 내용)
                let toolTip = document.querySelectorAll('.tooltip__group');
                if (toolTip.length > 0) {
                    toolTip.forEach(element => {
                        element.addEventListener('click', function (event) {
                            toolTipClass(event.target);
                            event.stopPropagation(); // 이벤트 전파 중단
                        });
                    });
                }

                function toolTipClass(target) {
                    let parent = target.parentElement;
                    while (parent) {
                        if (parent && parent.classList.contains('tooltip__group')) {
                            parent.classList.toggle('active');
                            break;
                        }
                        parent = parent.parentElement; // 부모 요소 업데이트
                    }
                }

                document.addEventListener('click', function (event) {
                    const clickedElement = event.target;
                    if (!clickedElement.classList.contains('tooltip__box')) {
                        toolTip.forEach(element => {
                            element.classList.remove('active');
                        });
                    }
                });
            },

            async saveCustBiz() {
                if (this.validateCustBiz()) {
                    this.isProcessing = true;
                    await this.insertCustBiz();
                    this.isProcessing = false;
                }
            },
            //사용자 정보 저장
            async insertCustBiz() {
                var param = {
                    "id": this.loginInfo.custBizList[0].id,
                    "custId": this.loginInfo.id,
                    "cmpNm": this.loginInfo.custBizList[0]['cmpNm'].toString(),
                    "cdltStCd": "0",
                    "cdltStId": this.selGrd,
                    "cdltStIssOrgnz": this.selOrgnz,
                    "cdltStDe": this.dateToString(this.cdltStDe) != '' ? this.dateToString(this.cdltStDe) : null,
                    "crasRate": this.crasRate,
                    "debtRate": this.debtRate,
                    "femaleCoYn": "N"
                };

                await this.$post('/api/cust/saveManagementStatus', param)
                    .then(async res => {
                        if (res.code == 'OK') {
                            await this.insertCustLicns();
                        }
                    })
                    .catch(error => {
                            console.log(error);
                        }
                    );

            },
            //면허 저장
            async insertCustLicns() {


                var bsnPerdCd = "";
                var bsnPerdDeText = "";

                var today = this.$moment();
                var bsnRegstDe = this.dateToString(this.bsnRegstDe) != '' ? this.dateToString(this.bsnRegstDe) : '';
                var selectDay = this.$moment(bsnRegstDe);
                var year = today.diff(selectDay, 'years');

                if (year >= 5) {
                    bsnPerdCd = '3';
                    bsnPerdDeText = '5년이상';
                } else if (year >= 3) {
                    bsnPerdCd = '2';
                    bsnPerdDeText = '3년~5년';
                } else if (year >= 1) {
                    bsnPerdCd = '1';
                    bsnPerdDeText = '1년~3년';
                } else {
                    bsnPerdCd = '0';
                    bsnPerdDeText = '1년미만';
                }

                var index = this.modifyCustLicnsList.findIndex((element) => element == this.modifySelectLicns);

                this.modifyCustLicnsList[index].y3AcrsltAmt = this.y3AcrsltAmt;
                this.modifyCustLicnsList[index].y5AcrsltAmt = this.y5AcrsltAmt;
                this.modifyCustLicnsList[index].cswkCapaEvltnAmt = this.cswkCapaEvltnAmt;
                this.modifyCustLicnsList[index].lcettSuplmtAcrsltAmt = this.lcettSuplmtAcrsltAmt;
                this.modifyCustLicnsList[index].bsnPerdCd = bsnPerdCd;
                this.modifyCustLicnsList[index].bsnRegstDe = bsnRegstDe;
                this.modifyCustLicnsList[index].bsnPerdDeText = bsnPerdDeText;


                var param = {
                    "custBizId": this.loginInfo.custBizList[0].id,
                    "list": this.modifyCustLicnsList
                };

                await this.$post('/api/cust/saveCustLicns', param)
                    .then(res => {
                        if (res.code == 'OK') {
                            this.$toast.success('내 경영/면허정보가 수정되었습니다. ');
                            //내 경영 정보
                            this.$parent.getCustInfo();
                            this.getCustLicnsList();
                            this.isEditLicnsInfo = false;
                        } else {
                            this.$toast.error('내 경영/면허정보 업데이트에 실패했습니다.');
                        }

                    })
                    .catch(error => {
                            console.log(error);
                        }
                    );

            },
            reloadCustInfo() {
                this.$parent.getCustInfo();
                this.getCustLicnsList();
            },
            validateCustBiz() {
                if (this.selGrd != '') {
                    if (this.selOrgnz == '' || this.cdltStDe == '' || this.dateToString(this.cdltStDe) == '') {
                        this.$toast.error('신용평가 항목을 전부 선택해 주세요.');
                        return false;
                    }
                } else if (this.debtRate == null || this.debtRate.toString() == '') {
                    this.$toast.error('부채비율을 입력해 주세요.');
                    return false;
                } else if (this.crasRate == null || this.crasRate.toString() == '') {
                    this.$toast.error('유동비율을 입력해 주세요.');
                    return false;
                }
                return true;
            },

            cdltStSelectBox() {
                /* 셀렉트박스 */
                const selectBox = document.querySelector('#cdleStSelectBox');
                if (selectBox) {
                    selectBox.addEventListener('click', () => selectBox.classList.toggle('on'));

                    /* 셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
                    document.addEventListener('click', (event) => {
                        const target = event.target;
                        if (!selectBox.contains(target)) {
                            selectBox.classList.remove('on');
                        }
                    });
                    /* /셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
                }

                const selectBoxItem = document.querySelector('#cdleStSelectBoxItem');
                if (selectBoxItem) {
                    selectBoxItem.addEventListener('click', (event) => {
                        this.cdltText = event.target.innerText;
                        this.selGrd = event.target.value;
                        if (this.selGrd == '') {
                            this.selOrgnz = '';
                            this.orgnzText = '발급기관을 선택해 주세요.';
                            this.cdltStDe = '';
                        }
                        selectBox.classList.remove('on');
                    });
                }
            },
            cdltStOrgSelectBox() {
                /* 셀렉트박스 */
                const selectBox = document.querySelector('#cdleStOrgSelectBox');
                if (selectBox) {
                    selectBox.addEventListener('click', () => selectBox.classList.toggle('on'));

                    /* 셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
                    document.addEventListener('click', (event) => {
                        const target = event.target;
                        if (!selectBox.contains(target)) {
                            selectBox.classList.remove('on');
                        }
                    });
                    /* /셀렉트박스 활성화 중 다른 곳 클릭시 꺼짐 */
                }

                const selectBoxItem = document.querySelector('#cdleStOrgSelectBoxItem');
                if (selectBoxItem) {
                    selectBoxItem.addEventListener('click', (event) => {
                        this.orgnzText = event.target.innerText;
                        this.selOrgnz = event.target.value;
                        selectBox.classList.remove('on');
                    });
                }
            },

            //면허 수정창 활성화
            modifyLicns() {
                var index = this.custLicnsList.findIndex((element) => element.id == this.selectLicns.id);

                if(index != -1) {
                    this.isEditLicnsInfo = true;
                    this.modifyCustLicnsList = _.cloneDeep(this.custLicnsList);
                    this.modifySelectLicns = this.modifyCustLicnsList[index];
                    this.debtRate = mock.custBiz.debtRate;
                    this.crasRate = mock.custBiz.crasRate;
                    this.y3AcrsltAmt = this.modifySelectLicns.y3AcrsltAmt;
                    this.y5AcrsltAmt = this.modifySelectLicns.y5AcrsltAmt;
                    this.cswkCapaEvltnAmt = this.modifySelectLicns.cswkCapaEvltnAmt;
                    this.lcettSuplmtAcrsltAmt = this.modifySelectLicns.lcettSuplmtAcrsltAmt;

                    this.selGrd = mock.custBiz.cdltStId;
                    this.selOrgnz = mock.custBiz.custPrfshList[0].prfshOrgnzNm;
                    this.cdltText = mock.custBiz.custPrfshList[0].prfshEtcNm;
                    this.orgnzText = mock.custBiz.custPrfshList[0].prfshOrgnzNm;

                    if (mock.custBiz.custPrfshList[0].proofRnwlDe != null) {
                        this.cdltStDe = this.$moment(mock.custBiz.custPrfshList[0].proofRnwlDe).format('YYYY-MM-DD');
                    }

                    if (this.selGrd == null) {
                        this.selGrd = '';
                    }
                    if (this.selOrgnz == null) {
                        this.selOrgnz = '';
                    }

                    if (this.cdltText == null || this.cdltText == '') {
                        this.cdltText = '등급을 선택해 주세요.';
                    }
                    if (this.orgnzText == null || this.orgnzText == '') {
                        this.orgnzText = '발급기관을 선택해 주세요.';
                    }

                    if (this.cdltStDe == null) {
                        this.cdltStDe = '';
                    }
                } else {
                    this.isPopNotExistCustLicns = true;
                }
            },
            /** 신용평가 목록*/
            getCdltSdList() {
                this.cdltStList = [];

                this.$http.get('/api/comnCode/cdltSt')
                    .then(res => {
                        this.cdltStList = res.data.cdltStList;
                        this.cdltStList = this.cdltStList.filter((e) => e.cdltStCd == '0');

                        setTimeout(() => {
                            this.cdltStSelectBox();
                        }, 1000);
                    })
                    .catch(error => console.log(error));
            },
            //발급기관 목록
            getCdltStOrgnz() {
                this.cdltStOrgList = [];

                var param = {"comnCdGroupId": "CDLT_ST_ISS_ORGNZ"};

                this.$http.post('/api/comnCode/list', param)
                    .then(res => {
                        this.cdltStOrgList = res.data.comnCodeList;

                        setTimeout(() => {
                            this.cdltStOrgSelectBox();
                        }, 1000);
                    })
                    .catch(error => console.log(error));
            },
            previos() {
                let slideCont = event.target.parentElement.parentElement.querySelector('.tok-slide--list');

                if (this.currentPosition < 40) {
                    this.currentPosition += 160;
                    slideCont.style.transform = `translateX(${this.currentPosition}px)`;
                    this.count += 1;
                }
            },
            next() {
                let slideCont = event.target.parentElement.parentElement.querySelector('.tok-slide--list');

                if (this.count > 0) {
                    this.currentPosition -= 160;
                    slideCont.style.transform = `translateX(${this.currentPosition}px)`;
                    this.count -= 1;
                } else {
                    return;
                }

            },

            clickLicns(licns) {

                this.licnsChange(licns);
            },
            licnsChange(licns) {
                var index = this.modifyCustLicnsList.findIndex((element) => element == this.modifySelectLicns);

                var bsnPerdCd = "";
                var bsnPerdDeText = "";

                var today = this.$moment();
                var bsnRegstDe = this.dateToString(this.bsnRegstDe);
                var selectDay = this.$moment(bsnRegstDe);
                var year = today.diff(selectDay, 'years');

                if (year >= 5) {
                    bsnPerdCd = '3';
                    bsnPerdDeText = '5년이상';
                } else if (year >= 3) {
                    bsnPerdCd = '2';
                    bsnPerdDeText = '3년~5년';
                } else if (year >= 1) {
                    bsnPerdCd = '1';
                    bsnPerdDeText = '1년~3년';
                } else {
                    bsnPerdCd = '0';
                    bsnPerdDeText = '1년미만';
                }

                this.modifyCustLicnsList[index].y3AcrsltAmt = this.y3AcrsltAmt;
                this.modifyCustLicnsList[index].y5AcrsltAmt = this.y5AcrsltAmt;
                this.modifyCustLicnsList[index].cswkCapaEvltnAmt = this.cswkCapaEvltnAmt;
                this.modifyCustLicnsList[index].lcettSuplmtAcrsltAmt = this.lcettSuplmtAcrsltAmt;
                this.modifyCustLicnsList[index].bsnPerdCd = bsnPerdCd;
                this.modifyCustLicnsList[index].bsnRegstDe = bsnRegstDe;
                this.modifyCustLicnsList[index].bsnPerdDeText = bsnPerdDeText;

                this.modifySelectLicns = licns;
                this.selectLicns = licns;
                this.y3AcrsltAmt = this.modifySelectLicns.y3AcrsltAmt;
                this.y5AcrsltAmt = this.modifySelectLicns.y5AcrsltAmt;
                this.cswkCapaEvltnAmt = this.modifySelectLicns.cswkCapaEvltnAmt;
                this.lcettSuplmtAcrsltAmt = this.modifySelectLicns.lcettSuplmtAcrsltAmt;
                this.bsnRegstDe = this.modifySelectLicns.bsnRegstDe;
            },

            dateToString(value) {
                // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
                if (value == '') return '';
                if(value == 'NaN-NaN-NaN') return '';

                // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
                var js_date = new Date(value);

                // 연도, 월, 일 추출
                var year = js_date.getFullYear();
                var month = js_date.getMonth() + 1;
                var day = js_date.getDate();

                // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
                if (month < 10) {
                    month = '0' + month;
                }

                if (day < 10) {
                    day = '0' + day;
                }
                if (year == '1970' && month == '01' && day == '01') {
                    return '';
                }

                // 최종 포맷 (ex - '2021-10-08')
                return year + '-' + month + '-' + day;
            },
            //내 면허 정보
            async getCustLicnsList() {
                await this.$get('/api/custBiz/' + this.loginInfo.custBizList[0].id + '/custLicns')
                    .then(res => {
                        if (res.code == 'OK') {
                            if (res.data.length != 0) {
                                this.custLicnsList = res.data;
                                this.modifyCustLicnsList = _.cloneDeep(res.data);
                                this.selectLicns = this.custLicnsList[0];
                                this.$moment(this.strtDate).format('YYYY-MM-DD');
                                this.modifySelectLicns = this.modifyCustLicnsList[0];
                                this.y3AcrsltAmt = this.modifyCustLicnsList[0].y3AcrsltAmt;
                                this.y5AcrsltAmt = this.modifyCustLicnsList[0].y5AcrsltAmt;
                                this.cswkCapaEvltnAmt = this.modifyCustLicnsList[0].cswkCapaEvltnAmt;
                                this.lcettSuplmtAcrsltAmt = this.modifyCustLicnsList[0].lcettSuplmtAcrsltAmt;

                                this.bsnRegstDe = this.custLicnsList[0].bsnRegstDe;
                                this.count = this.custLicnsList.length - 1;
                                this.currentPosition = 40;

                                //툴팁 정보 가져오기
                                this.getTooltipList();
                            } else {
                                this.isPopNotExistCustLicns = true;
                            }
                        }
                    })
                    .catch(error => {
                            console.log(error);
                        }
                    );
            },

        }
    }


</script>